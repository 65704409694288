import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { localeEs } from "./shared/utils/primeng-locale";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(private configPrime: PrimeNGConfig) {}

  ngOnInit() {
    this.configPrime.setTranslation(localeEs);
  }

  title = 'butaco-clientes';
}
