export const localeEs = {
    startsWith: "Comienza con",
    contains: "Contiene",
    notContains: "No contiene",
    endsWith: "Termina con",
    equals: "Igual a",
    notEquals: "No igual a",
    noFilter: "Sin filtro",
    lt: "Menor que",
    lte: "Menor o igual que",
    gt: "Mayor que",
    gte: "Mayor o igual que",
    dateIs: "Fecha es",
    dateIsNot: "Fecha no es",
    dateBefore: "Fecha es antes",
    dateAfter: "Fecha es después",
    clear: "Limpiar",
    apply: "Aplicar",
    matchAll: "Coincidir con todo",
    matchAny: "Coincidir con cualquiera",
    addRule: "Agregar regla",
    removeRule: "Eliminar regla",
    accept: "Sí",
    reject: "No",
    choose: "Escoger",
    upload: "Subir",
    cancel: "Cancelar",
    dayNames: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
    monthNames: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    weekHeader: "Sem",
    firstDayOfWeek: 1,
    dateFormat: "dd/mm/yy",
    weak: "Débil",
    medium: "Medio",
    strong: "Fuerte",
    passwordPrompt: "Ingrese una contraseña",
    emptyMessage: "No hay resultados"
  };