interface Environment {
    production: boolean;
    urlCheckCode: string;
    version:string;
    firebase: {
      emulator: boolean;
      apiKey: string;
      authDomain: string;
      projectId: string;
      storageBucket: string;
      messagingSenderId: string;
      appId: string;
      measurementId: string;
    };
  }
  
  export const environment: Environment = {
    production: false,
    urlCheckCode: 'https://us-central1-butaco-dev.cloudfunctions.net/publicFunc-checkCode',
    version:'1.4.10',
    firebase: {
      emulator: true,
      apiKey: 'AIzaSyCFMgC-WUXqBZ-Fp2am87pHLczbY5yKhrs',
      authDomain: 'butaco-dev.firebaseapp.com',
      projectId: 'butaco-dev',
      storageBucket: 'butaco-dev.appspot.com',
      messagingSenderId: '688851361388',
      appId: '1:688851361388:web:7279277e8643d2664b8249',
      measurementId: 'G-4ZQ564RW1R'
    }
  }; 